import { render, staticRenderFns } from "./PayItem.vue?vue&type=template&id=37624943&scoped=true"
import script from "./PayItem.vue?vue&type=script&lang=js"
export * from "./PayItem.vue?vue&type=script&lang=js"
import style0 from "./PayItem.vue?vue&type=style&index=0&id=37624943&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37624943",
  null
  
)

export default component.exports